import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app.jsx";
import "./scss/styles.scss";
import "./css/unsemantic-grid-base-no-ie7.css";
import "./css/unsemantic-grid-desktop-no-ie7.css";
import "./css/w3schoolsmodal.css";
import { UpdateCache } from "./js/db.js";

/* Setup local database  */
/*
db.Configuration.clear();
db.Configuration.put({Name:'EntityId', Value: '528b224e-95c2-4e3a-9a37-6cb073ae93f6'});
db.Configuration.put({Name:'StaffMemberId', Value: 'a3023c70-7953-11e9-bf27-3543ebf74c52'});
db.Configuration.put({Name:'FirstName', Value: 'Michael'});
db.Configuration.put({Name:'LastName', Value: 'Cutter'});
*/

const doWork = async () => {
  await UpdateCache();
};
doWork();

ReactDOM.render(<App />, document.getElementById("root"));
